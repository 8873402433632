var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"backgroundColor":_vm.colorBackground,"id":"sidebar"},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Mis Envíos',
          icon: 'nc-icon nc-delivery-fast',
          path: '/cliente/envios',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'RMA',
          icon: 'nc-icon nc-refresh-02',
          path: '/cliente/rma',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Envíos agrupados',
          icon: 'nc-icon nc-layers-3',
          path: '/cliente/envios-agrupados',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Importaciones',
          icon: 'nc-icon nc-cloud-upload-94',
          path: '*',
        }}},[_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
            name: 'Envíos',
            path: '/cliente/importacion',
          }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
            name: 'RMA',
            path: '/cliente/ImportacionesRma',
          }}})],1),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Gestión de Incidencias',
          icon: 'nc-icon nc-settings-tool-66',
          path: '/cliente/incidencias',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Facturas',
          icon: 'nc-icon nc-money-coins',
          path: '/cliente/facturas',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Libreta de Contactos',
          icon: 'nc-icon nc-badge',
          path: '/cliente/libretaContacto',
        }}}),_c('sidebar-item',{attrs:{"hideClassName":_vm.hideClassName,"link":{
          name: 'Usuarios',
          icon: 'nc-icon nc-single-02',
          path: '/cliente/usuarios',
        }}})]},proxy:true}])},[_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{on:{"click":_vm.toggleSidebar}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }